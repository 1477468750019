import React from "react";
import './App.css';
import Text from "./text";

// API and Context imports
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <Text/>
    </QueryClientProvider>
  );
}

export default App;
