// Importieren der benötigten Funktionen aus den SDKs
import { initializeApp } from "firebase/app";

// Verwenden Sie die Umgebungsvariablen für die Firebase-Konfiguration
const firebaseConfig = {
  apiKey: "AIzaSyD619Tbp983Rz7wu4rekBGL3TXnuRAmws4",
  authDomain: "kobert-4eac5.firebaseapp.com",
  projectId: "kobert-4eac5",
  storageBucket: "kobert-4eac5.appspot.com",
  messagingSenderId: "216845019118",
  appId: "1:216845019118:web:8e4e828f9514d35caa90ff"
};

// Initialisieren von Firebase
export const app = initializeApp(firebaseConfig);