// Importing Firebase Firestore functions for querying and data operations
import {
doc,
setDoc
} from "firebase/firestore";

// Importing the Firebase connection setup and Firestore instance getter
import { app } from "../Firebase/FirebaseConnection";
import { getFirestore } from "firebase/firestore";

// Importing React Query functions for data fetching and mutations
import { useMutation } from "react-query";

// Initializing Firebase Firestore and Storage services
const db = getFirestore(app);

export function useAutoSaveText() {  
    const mutation = useMutation(
      async ({ updatedText }) => {
        
        // Referenz zum spezifischen Produkt-Dokument
        const productDocRef = doc(db, "text", "1");

        // Erstelle oder aktualisiere das Dokument mit der ID "1"
        await setDoc(productDocRef, { text: updatedText }, { merge: true });
      },
      {
        onError: (error) => {
          console.error("Fehler beim Speichern des Textes:", error);
        },
      }
    );
  
    return mutation;
}