import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import { useAutoSaveText } from './API/POST';
import { useLoadText } from "./API/GET";

export function Text() {

  const [text, setText] = useState("");
  const useSave = useAutoSaveText();

  const handleChange = (event) => {
  const newText = event.target.value;
  setText(newText);

  console.log(newText)

  // Versuche, den Text automatisch zu speichern
  useSave.mutate({ productId: "1", updatedText: newText });
  };

  const { data, isLoading, isError } = useLoadText();

  useEffect(() => {
    if (!isLoading && !isError) {
      setText(data);
    }
  }, [data, isLoading, isError]);

  return (
    <div className="App">
      <TextField
        multiline
        value={text}
        onChange={handleChange}
        variant="outlined"
        rows={40}
        style={{
          width: '100vw',
          height: '100vh',
        }}
      />
    </div>
  );
}

export default Text;
