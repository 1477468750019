// Importing Firebase Firestore functions for querying and data operations
import {
    doc,
    getDoc,
  } from "firebase/firestore";
  
// Importing the Firebase connection setup and Firestore instance getter
import { app } from "../Firebase/FirebaseConnection";
import { getFirestore } from "firebase/firestore";
import { useQuery } from "react-query";

// Initializing Firebase Firestore and Storage services
const db = getFirestore(app);

export function useLoadText() {
  return useQuery('loadText', async () => {
    // Referenz zum spezifischen Produkt-Dokument
    const productDocRef = doc(db, "text", "1");
    
    // Hole das Dokument
    const docSnap = await getDoc(productDocRef);
    
    // Überprüfe, ob das Dokument existiert und gib seinen Wert zurück
    if (docSnap.exists()) {
      return docSnap.data().text;
    } else {
      console.log('Kein Dokument vorhanden');
      return "";
    }
  });
}